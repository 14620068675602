import Rating from "@material-ui/lab/Rating";
import _ from "lodash";
import { useState } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import SemanticList from "../../OffersList/OfferItem/SemanticList";
import styles from "./OfferDetails.module.scss";
import useSnowplowAnalytics from "../../../hooks/useSnowplowAnalytics";
import { SCHEMA_PURCHASE } from "../../../config/snowplow";

const OfferDetails = ({
  description,
  semantic: { semanticCategories, detectedSemanticCategories },
  opinions,
  item_id,
  item_name,
  index,
  location_id,
}) => {
  const { t } = useTranslation();

  const [, setOpen] = useState(false);
  const analytics = useSnowplowAnalytics();
  // eslint-disable-next-line no-unused-vars
  const handleOpen = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "purchase",
        language: window.localStorage.getItem("i18nextLng"),
        userProject: window.localStorage.getItem("partnerSlug"),
        userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
        items: [
          {
            item_id: item_id,
            item_name: item_name,
            index: index,
            location_id: location_id,
          },
        ],
      },
    });
    analytics.track("purchase", {
      contexts: [
        {
          schema: SCHEMA_PURCHASE,
          data: {
            event: "purchase",
            language: window.localStorage.getItem("i18nextLng"),
            userProject: window.localStorage.getItem("partnerSlug"),
            userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
            items: [
              {
                item_id: item_id,
                item_name: item_name,
                index: index,
                location_id: location_id,
              },
            ],
          },
        },
      ],
    });
    setOpen(true);
  };
  // eslint-disable-next-line no-unused-vars
  const handleClse = () => setOpen(false);

  // eslint-disable-next-line no-unused-vars
  const book_btn = () => {
    switch (window.localStorage.getItem("partnerSlug")) {
      case "demo_pl_classic_search":
        return t("id.button_book_id_classic");
      case "demo_en_classic_search":
        return t("id.button_book_id_classic");
      case "demo_pl_full_text_search":
        return t("id.button_book_id_fts");
      case "demo_en_full_text_search":
        return t("id.button_book_id_fts");
      default:
        return t("id.button_book_id");
    }
  };

  const opinionTitles = opinions?.rainbowOpinionTitle || [];
  const opinionContent = opinions?.rainbowOpinionContent || [];
  const opinionAdditionalInfos = opinions?.rainbowOpinionAdditionalInfo || [];
  const opinionAdvantages = opinions?.rainbowOpinionAdvantages || [];
  const opinionDisadvantages = opinions?.rainbowOpinionDisadvantages || [];
  const opinionCountNegativeRates = opinions?.rainbowOpinionCountNegativeRates || [];
  const opinionCountPositiveRates = opinions?.rainbowOpinionCountPositiveRates || [];
  const opinionRating = opinions?.rainbowOpinionRating || [];

  const semanticCategoriesParsed = _.transform(
    semanticCategories,
    (result, item, key) => {
      if (key !== "__typename" && item?.length > 0) {
        Object.values(item).map((model) => {
          let isDetected = false;
          Object.keys(detectedSemanticCategories).map((item) => {
            if (detectedSemanticCategories[item] && detectedSemanticCategories[item].includes(model)) {
              isDetected = true;
            }
          });
          return result.push({
            name: model,
            category_name: key,
            title: _.replace(model, /_/g, " "),
            query_exists: isDetected,
          });
        });
      }
    },
    []
  );

  return (
    <>
      <div className={styles.offerDetails}>
        <SemanticList semanticCategories={semanticCategoriesParsed} />
        <div className={styles.offerDetailsDescription}>{description}</div>
        <div className={styles.informationBox}>{t("informationBox")}</div>
        {opinionTitles.map((opinion, index) => (
          <section className={styles.opinions} key={`${opinion}_${index}`}>
            <div className={styles.opinion}>
              <div className={styles.opinionBox}>
                <h2 className={styles.opinionTitle}>{opinion}</h2>
                <Rating value={opinionRating[index]} readOnly precision={0.5} />
              </div>
              <div className={styles.opinionBox}>
                {opinionCountPositiveRates[index] && (
                  <>
                    <div className={styles.positiveOpinionCircle}>{opinionCountPositiveRates[index]}</div>
                    <span className={styles.ratingTitle}>{t("offer_page.offer_details.positive_rates")}</span>
                  </>
                )}
                {opinionCountNegativeRates[index] && (
                  <>
                    <div className={styles.negativeOpinionCircle}>{opinionCountNegativeRates[index]}</div>
                    <span className={styles.ratingTitle}>{t("offer_page.offer_details.negative_rates")}</span>
                  </>
                )}
              </div>
              <p>{opinionContent[index]}</p>
              {opinionAdvantages[index] && (
                <>
                  <h3>&#9996; {t("offer_page.offer_details.advantages")}</h3>
                  <p className={styles.opinionParagraph}>{opinionAdvantages[index]}</p>
                </>
              )}
              {opinionDisadvantages[index] && (
                <>
                  <h3>&#10007; {t("offer_page.offer_details.disadvantages")}</h3>
                  <p className={styles.opinionParagraph}>{opinionDisadvantages[index]}</p>
                </>
              )}
              <p>{opinionAdditionalInfos[index]}</p>
            </div>
          </section>
        ))}
      </div>
    </>
  );
};
export default OfferDetails;
