import React from "react";
import { Box, Slider } from "@mui/material";
import { useTranslation } from "react-i18next";

const minRangeDays = 3;
const maxRangeDays = 21;

const Period = ({ minDays, maxDays, newMinDays, newMaxDays, onChange }) => {
  const { t } = useTranslation();

  const marks = [
    { value: minRangeDays, label: `${minRangeDays} ${t("offers_page.filters.days")}` },
    { value: maxRangeDays, label: `${maxRangeDays} ${t("offers_page.filters.days")}` },
  ];

  return (
    <Box mb={6} mx={0}>
      <Slider
        disableSwap
        disabled={minDays === maxDays}
        min={minRangeDays}
        max={maxRangeDays}
        track="normal"
        marks={marks}
        defaultValue={[newMinDays, newMaxDays]}
        onChangeCommitted={onChange}
        valueLabelDisplay="auto"
        sx={{
          color: "#FFBB12",
          margin: "0 0 6px 10px",
          width: "calc(100% - 30px)",
          boxSizing: "border-box",
          ".MuiSlider-rail": {
            backgroundColor: "#ccc",
            height: "2px",
            borderRadius: 0,
          },
          ".MuiSlider-mark": {
            display: "none",
          },
          ".MuiSlider-markLabel": {
            fontSize: "12px",
            border: "1px solid #000",
            padding: "2px 6px",
            borderRadius: "3px",
            color: "#000",
            marginTop: 1,
            '&[data-index="0"]': {
              marginLeft: "18px",
            },
            '&[data-index="1"]': {
              marginLeft: "-20px",
            },
          },
        }}
      />
    </Box>
  );
};

export default Period;
