import React, { useContext, useEffect } from "react";

import { useNavigate, useSearchParams } from "react-router-dom";

import useAuth from "../../../hooks/useAuth";

import { Box } from "@mui/material";
import AuthContext from "../../../context/Auth0Context";
import { CircularProgress } from "@material-ui/core";

const LoginAuth0 = () => {
  const { loginWithRedirect, checkAuthorization } = useAuth();
  const [searchParams] = useSearchParams();
  const authContext = useContext(AuthContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (authContext.isInitialized && !!searchParams.get("code")) {
      checkAuthorization().then((isAuthorized) => {
        const href = localStorage.getItem("locationBeforeLogIn");
        if (isAuthorized && href) {
          navigate(`${href}`);
          window.location.reload();
        }
      });
    } else if (!authContext.isAuthenticated && authContext.isInitialized && !authContext.isLoggedOut) {
      loginWithRedirect();
    } else if (authContext.isInitialized && authContext.isAuthenticated) {
      navigate("/");
      window.location.reload();
    }
  }, [authContext.isInitialized, authContext.isAuthenticated, authContext.isLoggedOut]);

  return (
    <Box
      sx={{
        display: "flex",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
        minHeight: "400px",
      }}
    >
      <CircularProgress style={{ color: "#1c4155" }} />
    </Box>
  );
};

export default LoginAuth0;
