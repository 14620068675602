import Rating from "@material-ui/lab/Rating";
import { Box } from "@mui/material";
import { useContext, useState } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { SingleOfferContext } from "../../../context/SingleOfferContext";
import defaultImage from "../../../images/atif-zafrak.jpg";
import { Loader } from "../../Base";
import Loading from "../../Base/Loading";

import styles from "./OfferNav.module.scss";

import useSnowplowAnalytics from "../../../hooks/useSnowplowAnalytics";
import { SCHEMA_PURCHASE } from "../../../config/snowplow";

const OfferNav = ({ _details, item_id, item_name, index, location_id }) => {
  const { offer } = useContext(SingleOfferContext);
  const { t } = useTranslation();
  const [isImageError, setIsImageError] = useState(false);
  const [isImageLoading, setIsImageLoading] = useState(true);

  const [, setOpen] = useState(false);
  const analytics = useSnowplowAnalytics();

  // eslint-disable-next-line no-unused-vars
  const handleOpen = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "purchase",
        language: window.localStorage.getItem("i18nextLng"),
        userProject: window.localStorage.getItem("partnerSlug"),
        userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
        items: [
          {
            item_id: item_id,
            item_name: item_name,
            index: index,
            location_id: location_id,
          },
        ],
      },
    });
    analytics.track("purchase", {
      contexts: [
        {
          schema: SCHEMA_PURCHASE,
          data: {
            event: "purchase",
            language: window.localStorage.getItem("i18nextLng"),
            userProject: window.localStorage.getItem("partnerSlug"),
            userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
            items: [
              {
                item_id: item_id,
                item_name: item_name,
                index: index,
                location_id: location_id,
              },
            ],
          },
        },
      ],
    });
    setOpen(true);
  };
  // eslint-disable-next-line no-unused-vars
  const handleClose = () => setOpen(false);

  if (offer.offer === undefined) {
    return <Loader />;
  }

  const image = offer.offer.offerDetails.images[0] ? offer.offer.offerDetails.images[0].original : defaultImage;
  const category = offer.offer.offerDetails.property.category;
  const rating = category ? category / 10 : 0;
  const city = offer.offer.offerDetails.cities?.sourceName || offer.offer.offerDetails.cities[0]?.sourceName;
  const country = offer.offer.offerDetails.countries?.sourceName || offer.offer.offerDetails.countries[0]?.sourceName;
  const region = offer.offer.offerDetails.regions?.sourceName || offer.offer.offerDetails.regions[0]?.sourceName;

  const getNameFromData = (str) => {
    try {
      return Array.isArray(str) ? str.join(" / ") : JSON.parse(str).join(" / ");
    } catch (e) {
      return str;
    }
  };

  const imageOnErrorHandler = (_event) => {
    // event.currentTarget.style.display = "none";
    setIsImageError(true);
    setIsImageLoading(false);
  };

  return (
    <>
      <Box className={styles.imageWrapper}>
        {!isImageError && <img src={image} onError={imageOnErrorHandler} onLoad={() => setIsImageLoading(false)} />}
        {isImageError && !isImageLoading && <span>{t("offers_page.offers_list.no_image")}</span>}
        {isImageLoading && <Loading />}
      </Box>
      <h1>
        {offer.offer.offerDetails.name} {rating > 0 && <Rating value={rating} readOnly precision={0.5} />}
      </h1>
      <Box className={styles.locationWrapper}>
        {country && ` ${getNameFromData(country)}, `}
        {region && ` ${getNameFromData(region)}, `}
        {getNameFromData(city)}
      </Box>
    </>
  );
};

export default OfferNav;
