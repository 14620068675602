import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button } from "../../Base";
import { Box, Tooltip } from "@mui/material";
import styles from "./PriceInput.module.scss";
import icon from "../../../images/icons/search-solid.svg";

const PriceInput = ({ onChange, _minPrice, _maxPrice }) => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const priceParamsArray = Array.isArray(JSON.parse(urlParams.get("price")))
    ? JSON.parse(urlParams.get("price"))
    : JSON.parse(urlParams.get("price"))
    ? [JSON.parse(urlParams.get("price"))]
    : [];

  const minParam = priceParamsArray.length == 1 ? priceParamsArray[0].min : "";
  const maxParam = priceParamsArray.length == 1 ? priceParamsArray[0].max : "";
  const [min, setMin] = useState(minParam);
  const [max, setMax] = useState(maxParam);
  const [error, setError] = useState({ status: false, msg: "" });

  const onChangeBefore = (event) => {
    event.preventDefault();

    if (!isNaN(min) && !isNaN(max) && parseInt(min) < parseInt(max)) {
      setError({ status: false, msg: "" });
      onChange({ target: { value: "" } }, [parseInt(min), parseInt(max)]);
    } else if (min != undefined && max != undefined) {
      if (min == "" && max == "") {
        onChange();
      } else if (parseInt(min) >= parseInt(max)) {
        setError({ status: true, msg: t("offers_page.filters.priceInputErrorMin") });
      } else {
        setError({ status: true, msg: "Not a number" });
      }
    } else {
      onChange();
    }
  };

  return (
    <Tooltip followCursor title={t("offers_page.filters.priceToolTip")}>
      <Box className={styles.priceInput_Box}>
        <form className={styles.priceInput_container} onSubmit={onChangeBefore}>
          <label className={styles.priceInput_Label}>{t("offers_page.filters.from")}</label>
          <input value={min} onChange={(e) => setMin(e.target.value.replace(/\D/g, ""))} />
          <label className={styles.priceInput_Label}>{t("offers_page.filters.to")}</label>
          <input value={max} onChange={(e) => setMax(e.target.value.replace(/\D/g, ""))} />
          <Button className={styles.icon_button} type="submit">
            <img src={icon} alt="Search" className={styles.searchIcon}></img>
          </Button>
        </form>
        {error.status && <span className={styles.priceInput_Error}>{error.msg}</span>}
      </Box>
    </Tooltip>
  );
};

export default PriceInput;
