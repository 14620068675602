import React from "react";
import { Box } from "@mui/material";

import _ from "lodash";
import { STORAGE_THEME_NAME } from "config/consts";
import { HOME_PAGE_THEMES } from "./theme";

import styles from "./Login.module.scss";
import LoginAuth0 from "../../components/Authentication/LoginAuth0/LoginAuth0";

const getTheme = () => {
  const themeIndex = sessionStorage.getItem(STORAGE_THEME_NAME);

  if (themeIndex) {
    return HOME_PAGE_THEMES[themeIndex];
  } else {
    const randomKey = _.random(0, HOME_PAGE_THEMES.length - 1);
    sessionStorage.setItem(STORAGE_THEME_NAME, randomKey);
    return HOME_PAGE_THEMES[randomKey];
  }
};

const Login = () => {
  const homePageTheme = getTheme();

  window.onbeforeunload = () => {
    sessionStorage.removeItem(STORAGE_THEME_NAME);
  };

  return (
    <Box bgcolor={"#f5be44"} className={styles.themeWrapper}>
      <LoginAuth0 homePageTheme={homePageTheme} />
    </Box>
  );
};

export default Login;
