import { gql } from "@apollo/client";

export const GET_PARTNER_INFO = gql`
  query PartnerInfo {
    partnerInfo(languageCode: PL) {
      name
      slug
      solrCollection
    }
  }
`;
