import { Box, Checkbox } from "@mui/material";
import { useTranslation } from "react-i18next";
import styles from "./PriceRangeRadio.module.scss";

const PriceRangeRadio = ({ data, onChange }) => {
  const { t } = useTranslation();
  const urlParams = new URLSearchParams(window.location.search);
  const priceParamsArray = Array.isArray(JSON.parse(urlParams.get("price"))) ? JSON.parse(urlParams.get("price")) : [];
  const currency = window.localStorage.getItem("currency");

  return (
    <Box sx={{ mb: 5, pl: 3 }}>
      <ul className={styles.list}>
        {data?.ranges?.map((item, index) => {
          const key = `priceCheckbox-${index}`;
          const isChecked =
            priceParamsArray.filter((el) => el.min === item.range.min && el.max === item.range.max).length > 0;
          return (
            <li className={styles.item} key={key}>
              <Checkbox
                className={styles.checkbox}
                size="normal"
                checked={isChecked}
                value={[item.range.max, item.range.min]}
                onChange={onChange}
                id={key}
                disabled={item.count === 0}
              />
              <label className={item.count !== 0 ? "" : styles.isDisable}>
                {index === 0 || index === data.ranges.length - 1 ? (
                  index === 0 ? (
                    <span> {`${t("offers_page.filters.to")} ${item.max ? item.max : item.range.max}${currency}`} </span>
                  ) : (
                    <span>
                      {" "}
                      {`${t("offers_page.filters.from")} ${item.min ? item.min : item.range.min}${currency}`}{" "}
                    </span>
                  )
                ) : (
                  <span>{`${item.range.min}${currency} - ${item.range.max}${currency}`}</span>
                )}
              </label>
            </li>
          );
        })}
      </ul>
    </Box>
  );
};

export default PriceRangeRadio;
