import { Box, Button } from "@mui/material";
import styles from "./PeriodInput.module.scss";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import icon from "../../../images/icons/search-solid.svg";

const PeriodInput = ({ _title, _data, selected, _variantColor, onChange, _suffix, periodRange }) => {
  const urlParams = new URLSearchParams(window.location.search);
  const periodValue = urlParams.get("period") || "[]";
  const { t } = useTranslation();
  const [inputValue, setInputValue] = useState(
    JSON.parse(periodValue).length === 1 ? JSON.parse(periodValue)[0] : { min: null, max: null }
  );
  const [, setisInputState] = useState(false);
  const [error, setError] = useState({ status: false, msg: "" });

  let selectedHelp = selected || [{ min: null, max: null }];

  const onChangeInput = async (addDelete, event = {}) => {
    const periodRangeCopy = periodRange.slice();
    setisInputState(false);
    if (addDelete) {
      event.preventDefault();
      const min = parseInt(inputValue.min) || periodRangeCopy.sort((a, b) => a.value - b.value)[0].value;
      const max = parseInt(inputValue.max) || periodRangeCopy.sort((a, b) => b.value - a.value)[0].value;
      if (min <= max && min != null && max != null) {
        await onChange({ target: { value: `${min}-${max}` } }, [min, max]);
        setError({ status: false, msg: "" });
      } else {
        setError({ status: true, msg: t("offers_page.filters.priceInputErrorMin") });
      }
    } else {
      onChange(
        {
          target: {
            value: `${inputValue.min == null ? selectedHelp.min : parseInt(inputValue.min)}-${
              inputValue.max == null ? selectedHelp.max : parseInt(inputValue.max)
            }`,
          },
        },
        addDelete
      );
    }
  };

  return (
    <Box className={styles.priceInput_Box}>
      <form
        className={styles.priceInput_container}
        onSubmit={(event) => {
          onChangeInput(true, selectedHelp, event);
        }}
      >
        <label className={styles.priceInput_Label}>{t("offers_page.filters.from")}</label>
        <input
          defaultValue={inputValue.min == null && selected ? selectedHelp?.min : inputValue.min}
          value={inputValue.min}
          onChange={(event) =>
            setInputValue({
              min: event.target.value.replace(/\D/g, ""),
              max: inputValue.max == null ? selectedHelp?.max : inputValue.max,
            })
          }
        />
        <label className={styles.priceInput_Label}>{t("offers_page.filters.to")}</label>
        <input
          defaultValue={inputValue.max == null && selected ? selectedHelp?.max : inputValue.max}
          value={inputValue.max}
          onChange={(event) =>
            setInputValue({
              max: event.target.value.replace(/\D/g, ""),
              min: inputValue.min == null ? selectedHelp?.min : inputValue.min,
            })
          }
        />
        <Button className={styles.icon_button} type="submit">
          <img src={icon} alt="Search" className={styles.searchIcon}></img>
        </Button>
      </form>
      {error.status && <span className={styles.priceInput_Error}>{error.msg}</span>}
    </Box>
  );
};

export default PeriodInput;
