import React, { useState } from "react";
import classNames from "classnames";
import _ from "lodash";

import { Box, Checkbox, Typography } from "@mui/material";

import styles from "./CheckboxList.module.scss";
import { useTranslation } from "react-i18next";

const CheckboxList = ({ title, data, selected, variantColor, onChange, suffix, _periodRange }) => {
  const { t } = useTranslation();
  const [isShowAll, setIsShowAll] = useState(false);
  const wrapperClassName = classNames({
    [styles.wrapperList]: true,
    [styles.hasShadow]: false,
    [styles.variantColor]: variantColor,
  });
  let selectedHelp = selected || [{ min: null, max: null }];

  let checkedCount = 0;
  const currency = window.localStorage.getItem("currency");
  return (
    <Box mb={3} mx={0} borderRadius={4}>
      <Typography as="h3" color="#000" fontFamily="Sharp Grotesk Medium" fontSize="16px" fontWeight="bold" mb={1}>
        {title}
      </Typography>

      <Box className={wrapperClassName}>
        <ul className={styles.list}>
          {data.map(({ label, value, count, countOffers, minPrice, month, year, min, max }, index) => {
            const key = `filter-${_.kebabCase(title)}-${_.kebabCase(value)}-${index}`;
            let isChecked =
              selected === value ||
              (Array.isArray(selected) && selected.indexOf(value) !== -1) ||
              (Array.isArray(selected) && min && selected.find((el) => el.min === min && el.max === max));

            selectedHelp = isChecked
              ? selectedHelp.map((el) => {
                  return el.min == min && el.max == max ? {} : el;
                })
              : selectedHelp;

            if (month && year && selected) {
              if (
                selected.min.split("-")[1] == selected.max.split("-")[1] &&
                selected.min.split("-")[0] == selected.max.split("-")[0]
              ) {
                isChecked =
                  month === parseInt(selected.min.split("-")[1], 10) &&
                  year == parseInt(selected.min.split("-")[0], 10);
              }
            }
            if (isChecked && index > 2) {
              checkedCount += 1;
            }
            if (index <= 2 || isChecked || max) {
              if (max) {
                checkedCount += data.length;
              }

              return (
                <li className={styles.item} key={key}>
                  <Box>
                    <Checkbox
                      value={value}
                      defaultChecked={(isChecked && count !== 0) || (isChecked && min !== undefined)}
                      className={styles.checkbox}
                      size="small"
                      id={key}
                      onChange={onChange}
                      disabled={count === 0 && max === undefined}
                    />
                    {month ? (
                      <span className={count === 0 ? styles.disabledLabel : ""}>
                        {t(`offers_page.offers_list.month_${month}`)} {year}
                      </span>
                    ) : (
                      <span
                        className={
                          count === 0 && max === undefined ? styles.disabledLabel : max === undefined ? "" : styles.down
                        }
                      >
                        {label ? label : value.toString().replace(/_/g, " ")}{" "}
                        {max ? t("offers_page.offers_list.days") : ""}
                      </span>
                    )}
                  </Box>
                  <label htmlFor={key} title={value}>
                    {!!suffix && <span> {suffix}</span>}
                    {!!count && countOffers > 0 && <span className={styles.countOffers}>{countOffers}</span>}
                    {!!count && count > 0 && !max && (
                      <span className={`${styles.countOffers} ${styles.count}`}>{count}</span>
                    )}
                    {!!minPrice && (
                      <span className={`${styles.countOffers} ${styles.minPrice}`}>
                        {t("offers_page.filters.from") + " " + minPrice + currency}
                      </span>
                    )}
                  </label>
                </li>
              );
            } else if (isShowAll) {
              return (
                <li className={styles.item} key={key}>
                  <Box>
                    <Checkbox
                      value={value}
                      defaultChecked={isChecked}
                      className={styles.checkbox}
                      size="small"
                      id={key}
                      onChange={onChange}
                      disabled={count === 0 && max === undefined}
                    />

                    {month ? (
                      <span className={count === 0 ? styles.disabledLabel : ""}>
                        {t(`offers_page.offers_list.month_${month}`)} {year}
                      </span>
                    ) : (
                      <span className={count === 0 && max === undefined ? styles.disabledLabel : ""}>
                        {label ? label : value.toString().replace(/_/g, " ")} {max ? t("offers_page.filters.days") : ""}
                      </span>
                    )}
                  </Box>
                  <label htmlFor={key} title={value}>
                    {!!suffix && <span> {suffix}</span>}
                    {!!count && countOffers > 0 && <span className={styles.countOffers}>{countOffers}</span>}
                    {!!count && count > 0 && !max && (
                      <span className={`${styles.countOffers} ${styles.count}`}>{count}</span>
                    )}
                    {!!minPrice && (
                      <span className={`${styles.countOffers} ${styles.minPrice}`}>
                        {t("offers_page.filters.from") + minPrice + currency}
                      </span>
                    )}
                  </label>
                </li>
              );
            }
          })}
        </ul>
        {data.length > 3 + checkedCount && (
          <div>
            {isShowAll && (
              <p onClick={() => setIsShowAll(!isShowAll)} className={styles.showMoreLessToggle}>
                {t("offers_page.filters.show_less")}
              </p>
            )}
            {!isShowAll && (
              <p onClick={() => setIsShowAll(!isShowAll)} className={styles.showMoreLessToggle}>
                {t("offers_page.filters.show_all")} {data.length}
              </p>
            )}
          </div>
        )}
      </Box>
    </Box>
  );
};

export default CheckboxList;
