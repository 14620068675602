import { Box, Container, Typography } from "@mui/material";
import { QTRAVELAI } from "config/routes";
import qtravel from "images/qtravel.svg";
import { useTranslation } from "react-i18next";
import styles from "./Footer.module.scss";
import FooterSocial from "./FooterSocial";

const FooterElements = () => {
  const { t } = useTranslation();

  return (
    <Box className={styles.footerElements}>
      <Container maxWidth="lg">
        <Box className={styles.footerElementsWrapper}>
          <Box>
            <img src={qtravel} />
          </Box>
          <Box className={styles.column}>
            <Typography as="h3" className={styles.header}>
              {t("footer.products")}
            </Typography>

            <Typography mb={2}>
              <a href={QTRAVELAI + t("url.qtravel_search")} target="_blank">
                Qtravel Search
              </a>
            </Typography>

            <Typography>
              <a href={QTRAVELAI + t("url.qtravel_discovery")} target="_blank">
                Qtravel Discovery
              </a>
            </Typography>
          </Box>
          <Box className={styles.column}>
            <Typography as="h3" className={styles.header}>
              {t("footer.our_technology")}
            </Typography>

            <Typography mb={2}>
              <a href={QTRAVELAI + t("url.grands_for_innovations")} target="_blank">
                {t("footer.grants_for_innovation")}
              </a>
            </Typography>

            <Typography mb={2}>
              <a href={QTRAVELAI + t("url.roadmap")} target="_blank">
                {t("footer.roadmap")}
              </a>
            </Typography>

            <Typography mb={2}>
              <a href={QTRAVELAI + t("url.simple_api")} target="_blank">
                Travel Search & Discovery API
              </a>
            </Typography>
            <Typography>
              <a href={process.env.REACT_APP_TECHNICAL_DOCUMENTATION} target="_blank" rel="noopener noreferrer">
                Technical Documentation
              </a>
            </Typography>
          </Box>
          <Box className={styles.column}>
            <Typography as="h3" className={styles.header}>
              {t("footer.company")}
            </Typography>

            <Typography mb={2}>
              <a href={QTRAVELAI + t("url.about_us")} target="_blank">
                {t("footer.about_us")}
              </a>
            </Typography>

            <Typography mb={2}>
              <a href={QTRAVELAI + t("url.blog")} target="_blank">
                Blog
              </a>
            </Typography>

            <Typography mb={2}>
              <a href={QTRAVELAI + t("url.privacy")} target="_blank">
                {t("footer.privacy_policy")}
              </a>
            </Typography>

            <Typography mb={2}>
              <a href={QTRAVELAI + t("url.newsroom")} target="_blank">
                Newsroom
              </a>
            </Typography>

            <Typography mb={2}>
              <a href={QTRAVELAI + t("url.contact")} target="_blank">
                {t("footer.contact")}
              </a>
            </Typography>

            <Typography mb={2}>
              <a href={QTRAVELAI + t("url.terms")} target="_blank">
                {t("footer.terms")}
              </a>
            </Typography>
          </Box>
        </Box>
        <FooterSocial />
      </Container>
    </Box>
  );
};

export default FooterElements;
