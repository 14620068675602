import { useContext, useEffect } from "react";
import { Box, Container } from "@mui/material";
import Footer from "components/Footer";
import Header from "components/Header";
import HomeForm from "components/HomeForm";

import { STORAGE_THEME_NAME } from "config/consts";
import _ from "lodash";
import { HOME_PAGE_THEMES } from "./theme";

import { useTranslation } from "react-i18next";
import MetaTags from "react-meta-tags";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../context/Auth0Context";
import styles from "./Homepage.module.scss";

const getTheme = () => {
  const themeIndex = sessionStorage.getItem(STORAGE_THEME_NAME);
  const fallbackTheme = HOME_PAGE_THEMES[0];
  if (themeIndex) {
    return HOME_PAGE_THEMES[themeIndex] || fallbackTheme;
  } else {
    const randomKey = _.random(0, HOME_PAGE_THEMES.length - 1);
    sessionStorage.setItem(STORAGE_THEME_NAME, randomKey);
    return HOME_PAGE_THEMES[randomKey] || fallbackTheme;
  }
};

const Homepage = () => {
  const homePageTheme = getTheme();
  const navigate = useNavigate();
  const authContext = useContext(AuthContext);
  const { t } = useTranslation();

  useEffect(() => {
    if (!authContext.isAuthenticated && authContext.isInitialized) {
      navigate("/login");
    }
  }, [authContext.isInitialized, authContext.isAuthenticated]);

  window.onbeforeunload = () => {
    sessionStorage.removeItem(STORAGE_THEME_NAME);
  };

  return (
    <Box bgcolor={homePageTheme.background.bgcolor} className={styles.themeWrapper}>
      <MetaTags>
        <title>
          {t("metadata.homepage.title")} {window.localStorage.getItem("partnerSlug")}
        </title>
        <meta name="description" content={t("metadata.homepage.description")} />
      </MetaTags>
      <Container maxWidth="lg">
        <Header />
        <HomeForm homePageTheme={homePageTheme} />
      </Container>
      <Footer />
    </Box>
  );
};

export default Homepage;
