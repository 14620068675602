import { Button, Loader, Popup } from "components/Base";
import useSnowplowAnalytics from "hooks/useSnowplowAnalytics";
import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import styles from "./OfferReservationShort.module.scss";
import { useQuery } from "@apollo/client";
import { GET_TERM } from "GraphQL/GetTerm";
import useAuth from "hooks/useAuth";

const OfferReservationShort = ({ item_id, item_name, index, location_id, _query }) => {
  const urlString = window.location.search;
  const params = new URLSearchParams(urlString);
  const ArrayFilters = { adults: parseInt(params.get("adults")), children: parseInt(params.get("children")) };

  const { t } = useTranslation();
  const navigate = useNavigate();
  const auth = useAuth();
  const [term, setTerm] = useState({});
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const [open, setOpen] = useState(false);
  const analytics = useSnowplowAnalytics();

  const { loading, error, data } = useQuery(GET_TERM, {
    skip: !auth.isAuthenticated,
    variables: {
      countryCode: window.localStorage.getItem("countryCode"),
      languageCode: window.localStorage.getItem("languageCode"),
      id: params.get("termId"),
      filters: ArrayFilters,
    },
  });

  useEffect(() => {
    if (data) {
      setTerm(data);
    }
  }, [data]);

  useEffect(() => {
    if (term?.term?.offerDetails?.terms?.[0]?.departureDate) {
      setStartDate(new Date(term.term.offerDetails.terms[0].departureDate));
      const tmpDate = new Date(term.term.offerDetails.terms[0].departureDate);
      tmpDate.setDate(tmpDate.getDate() + term.term.offerDetails.terms[0].period);
      setEndDate(tmpDate);
    }
  }, [term]);

  const splitStartDate = {
    day: startDate.toISOString().slice(8, 10),
    month: startDate.toISOString().slice(5, 7),
  };
  const splitEndDate = {
    day: endDate.toISOString().slice(8, 10),
    month: endDate.toISOString().slice(5, 7),
    year: endDate.toISOString().slice(0, 4),
  };

  const book_btn = () => {
    switch (window.localStorage.getItem("partnerSlug")) {
      case "demo_pl_classic_search":
        return t("id.button_book_id_classic");
      case "demo_en_classic_search":
        return t("id.button_book_id_classic");
      case "demo_pl_full_text_search":
        return t("id.button_book_id_fts");
      case "demo_en_full_text_search":
        return t("id.button_book_id_fts");
      default:
        return t("id.button_book_id");
    }
  };

  const numberWithSpaces = (x) => {
    if (x) {
      var parts = x.toString().split(".");
      parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
      if (parts[1] && parts[1].length === 1) {
        parts[1] += "0";
      }
      return parts.join(".");
    } else {
      return x;
    }
  };

  const handleOpen = () => {
    TagManager.dataLayer({
      dataLayer: {
        event: "purchase",
        language: window.localStorage.getItem("i18nextLng"),
        userProject: window.localStorage.getItem("partnerSlug"),
        userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
        items: [
          {
            item_id,
            item_name,
            index,
            location_id,
          },
        ],
      },
    });

    analytics.track("purchase", {
      context: [
        {
          data: {
            event: "purchase",
            language: window.localStorage.getItem("i18nextLng"),
            userProject: window.localStorage.getItem("partnerSlug"),
            userId: window.localStorage.getItem("email").replace("@qtravel.ai", ""),
            items: [
              {
                item_id,
                item_name,
                index,
                location_id,
              },
            ],
          },
        },
      ],
    });

    setOpen(true);
  };

  const handleClose = () => setOpen(false);

  if (loading) return <Loader />;
  if (error || !term?.term?.offerDetails?.terms.length)
    return (
      <div className={styles.offerReservationShort} id="offerReservationShort">
        <div className={styles.noTerm}>{t("offer_page.no_term")}</div>
      </div>
    );

  return (
    <div className={styles.offerReservationShort} id="offerReservationShort">
      <div className={styles.reservationHeader}>{t("offer_page.selected_offer")}</div>
      <div className={styles.reservationFields}>
        {term?.term?.offerDetails?.terms?.[0]?.personPrices == null ? (
          <div className={styles.personsConfiguration}>
            <span className={styles.personsNumber}>{term?.term?.offerDetails?.terms?.[0]?.persons}&nbsp;</span>
            <span className={styles.personsType}>{t("offers_page.filters.adults")}</span>
          </div>
        ) : (
          <div className={styles.personsConfiguration}>
            <span className={styles.personsNumber}>
              {term?.term?.offerDetails?.terms?.[0]?.personsConfiguration?.adults}&nbsp;
            </span>
            <span className={styles.personsType}>{t("offers_page.filters.adults")}</span>
            {term?.term?.offerDetails?.terms?.[0]?.personsConfiguration?.children > 0 && (
              <>
                <span className={styles.personsSeparator}>,&nbsp;</span>
                <span className={styles.personsNumber}>
                  {term?.term?.offerDetails?.terms?.[0]?.personsConfiguration?.children}&nbsp;
                </span>
                <span className={styles.personsType}>{t("offers_page.filters.children")}</span>
              </>
            )}
          </div>
        )}

        <div className={styles.period}>
          <span className={styles.period}>
            {splitStartDate?.day}.{splitStartDate?.month} - {splitEndDate?.day}.{splitEndDate?.month}.
            {splitEndDate?.year}
          </span>
        </div>

        <div className={styles.departureCity}>
          <span className={styles.departureCity}>{term?.term?.offerDetails?.terms?.[0]?.departureCity}</span>
        </div>

        <div className={styles.maintenances}>
          <span className={styles.maintenancesType}>
            {term?.term?.offerDetails?.terms?.[0]?.maintenances?.[0]?.sourceName}
          </span>
        </div>
      </div>

      <div className={styles.reservationSummary}>
        <div className={styles.reservationPrices}>
          <div className={styles.personPrices}>
            <span className={styles.personPriceTag}>{t("offer_page.price_per_person")}:</span>
            {term?.term?.offerDetails?.terms?.[0]?.personPrices == null ? (
              <span className={styles.personPrice}>
                {numberWithSpaces(term?.term?.offerDetails?.terms?.[0]?.basePrice)}
                &nbsp;
                {window.localStorage.getItem("currency")}
              </span>
            ) : (
              <span className={styles.personPrice}>
                {numberWithSpaces(
                  term?.term?.offerDetails?.terms?.[0]?.personPrices.reduce(
                    (acc, curr) => {
                      if (curr.type === "ADULT") {
                        acc[0] = curr.price;
                      }
                      return acc;
                    },
                    [0]
                  )[0]
                )}
                &nbsp;{window.localStorage.getItem("currency")}
              </span>
            )}
          </div>

          <div className={styles.total}>
            <span className={styles.totalPriceTag}>{t("offer_page.terms.price")}:</span>
            {term?.term?.offerDetails?.terms?.[0]?.personPrices == null ? (
              <span className={styles.totalPrice}>
                {numberWithSpaces(
                  term?.term?.offerDetails?.terms?.[0]?.basePrice * term?.term?.offerDetails?.terms?.[0]?.persons
                )}
                &nbsp;{window.localStorage.getItem("currency")}
              </span>
            ) : (
              <span className={styles.totalPrice}>
                {numberWithSpaces(
                  term?.term?.offerDetails?.terms?.[0]?.personPrices.reduce((acc, curr) => acc + curr.price, 0)
                )}
                &nbsp;{window.localStorage.getItem("currency")}
              </span>
            )}
          </div>
        </div>

        <div className={styles.reservationButton}>
          <Button className={styles.buttonBook} id={book_btn()} onClick={handleOpen}>
            {t("offers_page.offers_list.button_book")}
          </Button>
        </div>
      </div>

      <Popup
        open={open}
        onClose={handleClose}
        title={t("offer_page.popup.title")}
        button={t("offer_page.popup.button")}
        onClick={() => navigate("/")}
      ></Popup>
    </div>
  );
};

export default OfferReservationShort;
