import { gql } from "@apollo/client";

export const GET_FULL_PARTNER_INFO = gql`
  query PartnerInfo {
    partnerInfo(languageCode: PL) {
      autocomplete
      debugFields
      name
      nlpConfig
      slug
      solrCollection
    }
  }
`;
