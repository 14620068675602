import { Box, Slider } from "@mui/material";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const PriceRange = ({ minPrice, maxPrice, newMinPrice, newMaxPrice, onChange }) => {
  const [minPriceValue, setminPriceValue] = useState(newMinPrice);
  const [maxPriceValue, setmaxPriceValue] = useState(newMaxPrice);

  const { t } = useTranslation();
  let marks = [];
  const currency = window.localStorage.getItem("currency");

  if (minPrice && maxPrice && minPrice !== maxPrice) {
    marks = [
      { value: minPrice, label: `${t("offers_page.filters.from")} ${newMinPrice.toFixed(2)} ${currency}` },
      { value: maxPrice, label: `${t("offers_page.filters.to")} ${newMaxPrice.toFixed(2)} ${currency}` },
    ];
  } else if (minPrice) {
    marks = [{ value: minPrice, label: `${minPrice.toFixed(2)} ${currency}` }];
  } else if (maxPrice) {
    marks = [{ value: maxPrice, label: `${maxPrice.toFixed(2)} ${currency}` }];
  }

  const additionalStyle =
    minPrice !== maxPrice
      ? {
          "&:": {
            marginLeft: "-22px",
          },
        }
      : {};

  useEffect(async () => {
    await setminPriceValue(newMinPrice);
    await setmaxPriceValue(newMaxPrice);
  }, [newMinPrice, newMaxPrice]);

  const chanheHandle = (event) => {
    const [min, max] = event.target.value;
    setmaxPriceValue(max);
    setminPriceValue(min);
  };
  return (
    <Box mb={6} mx={0}>
      {/* <Typography as="h3" color="#000" fontFamily="Sharp Grotesk Medium" fontSize="16px" fontWeight="bold" mb={1}>
        {t("offers_page.filters.price")}
      </Typography> */}
      <Slider
        disableSwap
        track="normal"
        step={50}
        marks={marks}
        min={minPrice}
        max={maxPrice}
        defaultValue={[minPriceValue, maxPriceValue]}
        value={[minPriceValue, maxPriceValue]}
        onChange={chanheHandle}
        onChangeCommitted={onChange}
        valueLabelDisplay="auto"
        sx={{
          color: "#FFBB12",
          margin: "0 0 6px 10px",
          width: "calc(100% - 30px)",
          boxSizing: "border-box",
          ".MuiSlider-marked": {
            position: "relative",
          },
          ".MuiSlider-rail": {
            backgroundColor: "#ccc",
            height: "2px",
            borderRadius: 0,
          },
          ".MuiSlider-mark": {
            display: "none",
          },
          ".MuiSlider-markLabel": {
            fontSize: "12px",
            border: "1px solid #000",
            padding: "2px 6px",
            borderRadius: "3px",
            color: "#000",
            marginTop: 1,
            additionalStyle,
            '&[data-index="0"]': {
              marginLeft: "20px",
            },
            '&[data-index="1"]': {
              marginLeft: "-24px",
            },
          },
        }}
      />
    </Box>
  );
};

export default PriceRange;
