import React, { useEffect } from "react";
import { useState } from "react";
import styles from "./DatePickerRange.module.scss";
import { styled } from "@mui/material/styles";
import { TextField } from "@material-ui/core";
import AdapterDateFns from "@mui/lab/AdapterDateFns";
import MuiDateRangePickerDay from "@mui/lab/DateRangePickerDay";
import { LocalizationProvider, DateRangePicker } from "@mui/lab";
import { Calendar22, ArrowExpand } from "components/Icons";
import { Stack, Typography } from "@mui/material";
import { format } from "date-fns";
import _ from "lodash";
import { useTranslation } from "react-i18next";

const DateRangePickerDay = styled(MuiDateRangePickerDay)(
  ({ _theme, isHighlighting, outsideCurrentMonth, isStartOfHighlighting, isEndOfHighlighting }) => ({
    ".MuiDateRangePickerDay-day": {
      color: "#000",
    },
    ".MuiDateRangePickerDay-day.Mui-selected": {
      backgroundColor: "#FF7427",
      color: "#000",
    },
    ".MuiDateRangePickerDay-day.Mui-disabled": {
      color: "rgba(0, 0, 0, 0.38)",
    },
    ".MuiDateRangePickerDay-day:hover, .MuiDateRangePickerDay-day:focus": {
      color: "#000",
      backgroundColor: "#FDD500",
      borderColor: "#FDD500",
    },
    ".MuiDateRangePickerDay-day:focus.Mui-selected, .MuiDateRangePickerDay-day:hover.Mui-selected": {
      borderColor: "#FF7427",
      backgroundColor: "#FF7427",
      color: "#000",
    },
    ...(isHighlighting && {
      backgroundColor: "#FFBB12",
      color: "#000",
      "&:hover, &:focus": {
        backgroundColor: "#FFBB12",
        color: "#000",
      },
    }),
    ...(outsideCurrentMonth && {
      visibility: "hidden",
    }),

    ...(isStartOfHighlighting && {
      backgroundColor: "#FFBB12",
      color: "#000",
      borderTopLeftRadius: "50%",
      borderBottomLeftRadius: "50%",
      ".MuiDateRangePickerDay-day.Mui-selected": {
        backgroundColor: "#FF7427",
      },
      ".MuiDateRangePickerDay-day.Mui-selected:hover": {
        backgroundColor: "#FF7427",
        color: "#000",
      },
    }),
    ...(isEndOfHighlighting && {
      backgroundColor: "#FFBB12",
      color: "#000",
      borderTopRightRadius: "50%",
      borderBottomRightRadius: "50%",
      ".MuiDateRangePickerDay-day.Mui-selected": {
        backgroundColor: "#FDD500",
      },
      ".MuiDateRangePickerDay-day.Mui-selected:hover": {
        opacity: 0.6,
      },
    }),
  })
);

const DatePickerRange = ({ title, minDate, maxDate, onChange, dailyPrices, displayStyle, initialDates }) => {
  const minPrices =
    dailyPrices?.reduce((acc, curr) => {
      acc[curr.value] = curr.minPrice;
      return acc;
    }, {}) || "Classic";
  const { t } = useTranslation();
  const [selectedDates, setSelectedDates] = useState([
    new Date(minDate) < new Date() ? format(new Date(), "yyyy-MM-dd") : minDate,
    maxDate,
  ]);
  const [open, setOpen] = useState(false);
  const [, setError] = useState([]);

  useEffect(() => {
    if (initialDates && initialDates.min && initialDates.max) {
      setSelectedDates([initialDates.min, initialDates.max]);
    }
  }, [initialDates]);

  const formatDate = (date) => format(new Date(date), "yyyy-MM-dd");

  const renderWeekPickerDay = (date, dateRangePickerDayProps) => (
    <React.Fragment>
      {minPrices != "Classic" &&
        date >= new Date() &&
        !dateRangePickerDayProps.outsideCurrentMonth &&
        date.getDay() === 6 && <div className={styles.labelLast}>{minPrices[formatDate(date)]}</div>}
      <DateRangePickerDay {...dateRangePickerDayProps}></DateRangePickerDay>
      {minPrices != "Classic" &&
        date >= new Date() &&
        !dateRangePickerDayProps.outsideCurrentMonth &&
        date.getDay() !== 6 && <div className={styles.label}>{minPrices[formatDate(date)]}</div>}
    </React.Fragment>
  );

  const handleChange = (dates) => {
    if (open) {
      setSelectedDates(dates);
    }
  };

  const handleAccept = (dates) => {
    if (open) {
      const newDates = _.map(dates, (date) => {
        if (date && _.isDate(date)) return formatDate(date);
        else return null;
      });
      setOpen(false);
      onChange(newDates);
    }
  };

  const handleError = ([startDate, endDate]) => {
    setError([startDate, endDate]);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter" && event.target.value === "") {
      const resetDates = [null, null];
      setSelectedDates(resetDates);
      setOpen(false);
      onChange(resetDates);
    }
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Typography as="h3" color="#000" fontFamily="Sharp Grotesk Medium" fontSize="16px" fontWeight="bold" mb={2}>
        {title}
      </Typography>
      <DateRangePicker
        disablePast
        shouldDisableDate={minPrices != "Classic" ? (date) => !(formatDate(date) in minPrices) : null}
        startText={t("offers_page.filters.from")}
        endText={t("offers_page.filters.to")}
        value={selectedDates}
        inputFormat="dd.MM.yyyy"
        mask="__.__.____"
        open={open}
        onChange={handleChange}
        onAccept={handleAccept}
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        onError={handleError}
        autoFill={true}
        renderDay={renderWeekPickerDay}
        renderInput={(startProps, endProps) => (
          <Stack direction={displayStyle === "row" ? "row" : "column"} spacing={2}>
            <TextField
              {...startProps}
              error={false}
              name="minDate"
              variant="outlined"
              size="small"
              autoComplete="off"
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: <Calendar22 sx={{ marginRight: 1, pointerEvents: "none" }} />,
                endAdornment: <ArrowExpand sx={{ width: "0.64em !important", pointerEvents: "none" }} />,
              }}
            />
            <TextField
              style={{ marginBottom: "20px" }}
              {...endProps}
              error={false}
              name="maxDate"
              variant="outlined"
              size="small"
              autoComplete="off"
              onKeyDown={handleKeyDown}
              InputProps={{
                startAdornment: <Calendar22 sx={{ marginRight: 1, pointerEvents: "none" }} />,
                endAdornment: <ArrowExpand sx={{ width: "0.64em !important", pointerEvents: "none" }} />,
              }}
            />
          </Stack>
        )}
      />
    </LocalizationProvider>
  );
};

export default DatePickerRange;
