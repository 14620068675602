import { Box, Divider, Grid, Tooltip, Button, Typography } from "@mui/material";
import React, { useState } from "react";
import style from "./FilterCompose.module.scss";
import { Info } from "components/Icons";

const FilterCompose = ({ children, title, toolTip, items }) => {
  const [index, setIndex] = useState(parseInt(localStorage.getItem(title)) || 0);

  const changeIndex = (argIndex) => {
    localStorage.setItem(title, argIndex);
    setIndex(argIndex);
  };

  return (
    <Box style={{ marginBlock: "20px" }}>
      <Grid container>
        <Tooltip title={toolTip} placement={"left-end"}>
          <Box>
            <Info className={style.propertyIcon} />
          </Box>
        </Tooltip>
        <Typography as="h3" color="#000" fontFamily="Sharp Grotesk Medium" fontSize="16px" fontWeight="bold" mb={1}>
          {title}
        </Typography>
      </Grid>
      <Grid container className={style.FilterCompose_container}>
        {items.map((el, indexArg) => {
          return (
            <Grid item key={`${el}_${indexArg}`}>
              <Button
                onClick={() => changeIndex(indexArg)}
                className={`${index === indexArg ? style.active : style.Not_active_button}`}
              >
                {el}
              </Button>
            </Grid>
          );
        })}

        {/* <Grid item>
          <Button onClick={() => changeIndex(1)} className={`${index === 1 ? style.active : style.Not_active_button} `}>
          {items[1]}
          </Button>
        </Grid>
        <Grid item>
          <Button onClick={() => changeIndex(2)} className={`${index === 2 ? style.active : style.Not_active_button}`}>
          {items[2]}
          </Button>
        </Grid> */}
      </Grid>
      <Divider className={style.divider} />
      <Box>{React.Children.toArray(children)[index]}</Box>
    </Box>
  );
};
export default FilterCompose;
